@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.co-footer {
    $el: &;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: spacing(xl);
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 25px solid $color-main;
    @media (min-width: breakpoint(lg)) {
        flex-direction: row;
        padding: spacing(l) spacing(xl);
    }
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        @media (min-width: breakpoint(md)) {
            flex-direction: row;
            justify-content: space-between;
        }
        &-left {
            display: flex;
            align-items: center;
            margin-bottom: spacing(l);
             @media (min-width: breakpoint(lg)) {
                margin-bottom: 0;
            }
            & span {
                @include font-16-reg();
                color: $color-white;
            }
        }
        &-center {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            & > img {
                max-width: 64px;
            }
        }
        &-right {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: spacing(l);
            @media (min-width: breakpoint(md)) {
                margin-top: 0;
                flex-direction: row;
            }
        }
    }
    &__text {
        @include font-16-reg();
        color: $color-white;
        & a {
            @include font-16-reg();
            color: $color-white;
        }
    }
    @at-root .path-frontpage {
        @media (min-width: breakpoint(lg)) {
            #{$el} {
                border-top: 0;
                padding: spacing(m) spacing(xl);
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
            }
            #{$el}__inner-center {
                display: none;
            }
        }
    }
}