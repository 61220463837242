@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cp-home {
  $el: &;
  position: relative;
  padding: 0 spacing(m);
  @media (min-width: breakpoint(lg)) {
    padding: 0;
  }
  &__intro {
    position: relative;
    width: 100%;
    height: 89vh;
    overflow: hidden;
    z-index: 2;
    @media (min-width: breakpoint(lg)) {
      height: 100vh;
    }
    &-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 95%;
      transform: translate(-50%, -50%);
      text-align: center;
      @media (min-width: breakpoint(lg)) {
        width: auto;
      }
      & img {
        position: relative;
        display: block;
        max-width: 250px;
        margin: 0 auto;
        width: 95%;
        margin-bottom: spacing(l);
        @media (min-width: breakpoint(lg)) {
          width: auto;
          max-width: 20vw;
        }
      }
    }
    &-text {
      position: relative;
      @include font-38-black();
      color: $color-main;
    }
    &-scroll {
      position: absolute;
      bottom: spacing(l);
      left: 50%;
      transform: translateX(-50%);
      width: spacing(xl);
      display: none;
      @media (min-width: breakpoint(lg)) {
        display: block;
      }
    }
  }
  &__idea {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 2;
    margin-top: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      margin: auto;
      height: 100vh;
      flex-direction: row;       
    }
    &-left {
      @media (min-width: breakpoint(lg)) {
        width: 40%;
      }
      @media (min-width: breakpoint(xl)) {
        width: 50%;
      }
    }
    &-right {
      @media (min-width: breakpoint(lg)) {
        width: 60%;
      }
      @media (min-width: breakpoint(xl)) {
        width: 50%;
      }
    }
  }
  &__gallery {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 2;
    margin-top: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      margin: auto;
      height: 100vh;
      flex-direction: row;       
    }
    &-left {
      @media (min-width: breakpoint(lg)) {
        width: 40vw;
      }
    }
    &-right {
      @media (min-width: breakpoint(lg)) {
        width: 60vw;
      }
    }
  }
}

.section {
  background-color: $color-body-bg;
  padding: 0 spacing(m);
  @media (min-width: breakpoint(lg)) {
    padding: 0;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }
}

@at-root .path-frontpage {
    html, body.path-frontpage {
      overflow: auto !important;
      @media (min-width: breakpoint(lg)) {
        overflow: hidden !important;
      }
    }
    .bg-lines {
        display: none;
    }
}