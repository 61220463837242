// COLORI
$color-white: #FFFFFF;
$color-black: #000000;
$color-transparent: rgba(255, 255, 255, 0);
$color-dark-transparent: rgba(87, 86, 86, 0.85);
$color-white-transparent: rgba(255, 255, 255, 0.5);

$color-main: #efb648;
$color-main-transparent: rgba(239, 182, 72, 0.75);
$color-main-dark: #d0992e;

$color-body-bg: #575656;
$color-text: #ffffff;
