@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.co-form {
  $el: &;
  padding: spacing(m);
  -webkit-overflow-scrolling: touch;
  @media (min-width: breakpoint(lg)) {
    padding: spacing(l) spacing(m);
  }
  & input[type='text'],
  & input[type='email'],
  & input[type='number'] {
    border: none;
    border-bottom: 1px solid $color-white;
    background-color: $color-transparent;
    box-shadow: none;
    border-radius: 0;
    color: $color-white;
    padding: spacing(m) 0;
    width: 100%;
    margin-bottom: spacing(m);
    margin-top: 0;
    -webkit-overflow-scrolling: touch;
    &::placeholder {
      color: $color-white;
      opacity: 0.75;
    }
  }
  & textarea {
    border: none;
    border: 1px solid $color-white;
    background-color: $color-transparent;
    box-shadow: none;
    border-radius: 0;
    color: $color-white;
    padding: spacing(m);
    resize: none;
    width: 100%;
    min-height: 150px;
    margin-bottom: spacing(l);
    margin-top: spacing(m);
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-overflow-scrolling: touch;
  }
  & input[type='submit'] {
    display: inline-block;
    width: auto;
    padding: spacing(m) spacing(l);
    margin-top: 40px;
    margin-left: auto;
    margin-right: spacing(m);
    background-color: $color-main;
    border: none;
    border: 2px solid $color-main;
    padding: (spacing(s) + spacing(xs)) spacing(m);
    color: $color-white !important;
    border-radius: spacing(xl);
    @include font-16-black();
    text-transform: uppercase;
    transition: all 350ms ease-in-out;
    -webkit-overflow-scrolling: touch;
    &:hover {
      background: $color-white;
      color: $color-main !important;
    }
    @media (min-width: breakpoint(lg)) {
      margin-top: spacing(l);
      margin-right: 0;
    }
  }
  & .js-form-type-checkbox {
    display: flex;
    align-items: flex-start;

    & input[type='checkbox'] {
      width: spacing(m);
      height: spacing(m);
      margin-right: spacing(m);
      margin-bottom: spacing(m);
    }
  }
}
#block-publitek-webform-richiesta-info {
  width: 100%;
  @media (min-width: breakpoint(lg)) {
    width: 80%;
  }
}