@use 'colors' as *;
@use 'typography' as *;
@use 'spaces' as *;
@use 'breakpoints' as *;
@use 'z-index' as *;
@use '../tools/typography/base-font-primary' as *;
body {
  font-family: $font-family-primary;
  margin: 0;
  padding: 0;
  line-height: 1;
  background-color: $color-body-bg;
  background-color: $color-body-bg;
  color: $color-white;
}

.placeholder {
  cursor: auto;
  background-color: $color-transparent;
  min-height: auto;
  opacity: 1;
  font-style: normal;
  vertical-align: baseline;
}

p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  color: $color-main;
}
.visually-hidden {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}

.inview-elem {
  position: relative;
  opacity: 0;
  top: -15px;
  transition: all 850ms ease-in-out;
  &--slow {
    transition: all 1250ms ease-in-out;
  }
}
.inview-elem.is-inview-elem {
  opacity: 1;
  top: 0;
}

.inview-elem-opacity {
  opacity: 0;
  transition: all 850ms ease-in-out;
}
.inview-elem-opacity.is-inview-elem-opacity {
  opacity: 1;
}


.bg-lines, .bg-lines-1, .bg-lines-2, .bg-lines-3  {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: .20;
  overflow: hidden;
  display: none;
}
@media (min-width: breakpoint(lg)) {
  .bg-lines, .bg-lines-1, .bg-lines-2, .bg-lines-3  {
    display: block;
  }
}

.bg-lines-obj {
  position: relative;
  width: 100%;
}