@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.ca-toggler {
  $el: &;
  display: block;
  position: relative;
  width: spacing(l) + spacing(m);
  height: spacing(l) + spacing(m);
  border-radius: 100%;
  background-color: $color-transparent;
  transition: all 850ms ease-in-out;
  cursor: pointer;
  @media (min-width: breakpoint(sm)) {
    width: 80px;
    height: 80px;
  }
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__line {
    position: relative;
    width: spacing(l) + spacing(s);
    height: spacing(xs);
    margin-top: spacing(xs);
    margin-bottom: spacing(xxs);
    border-radius: spacing(xs);
    background: $color-main;
    transition: all 350ms ease-in-out;
    align-self: end;
  }
  &__line:nth-child(1),
  &__line:nth-child(3) {
    width: spacing(l);
  }
  &.is-open {
    // background: $color-main;
  }
  &.is-open &__line {
    // background: $color-white;
  }
  &.is-open &__line:nth-child(1) {
    width: 70%;
    margin: 0;
    transform: translate(0px, 2px) rotate(45deg);
  }
  &.is-open &__line:nth-child(2) {
    margin: 0;
    width: 0;
    height: 0;
  }
  &.is-open &__line:nth-child(3) {
    width: 70%;
    margin: 0;
    transform: translate(0px, -2px) rotate(-45deg);
  }
  @media (min-width: breakpoint(lg)) {
    &:hover {
      #{$el}__line:nth-child(1),
      #{$el}__line:nth-child(3) {
        width: spacing(l) + spacing(s);
      }
    }
    &.is-open:hover {
      transform: rotate(-180deg);
    }
  }
}
