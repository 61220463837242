@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.co-header {
  $el: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: spacing(m) spacing(m);
  transition: all 350ms ease-in-out;
  background-color: $color-transparent;
  @media (min-width: breakpoint(lg)) {
    padding: spacing(l) spacing(m);
  }
  @media (min-width: breakpoint(xl)) {
    padding: spacing(l) spacing(l);
  }
  @media (min-width: breakpoint(xxl)) {
    padding: (spacing(l) + spacing(s)) spacing(xl);
  }
  &__toggler-box {
    position: absolute;
    top: spacing(xs);
    right: spacing(m);
    z-index: 110;
    @media (min-width: breakpoint(lg)) {
      display: none;
    }
  }
  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    @media (min-width: breakpoint(lg)) {
      padding: 0;
      margin: 0;
      width: 100%;
      flex-flow: nowrap;
      flex-direction: row;
      justify-content: center;
    }
    &-left {
      position: relative;
      @media (min-width: breakpoint(lg)) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    &-center {
      width: 100%;
    }
    &-right {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: none;
      & > span {
        min-width: 185px;
      }
      @media (min-width: breakpoint(lg)) {
        display: block;
      }
    }
  }
  &__logo {
    position: relative;
    width: spacing(l) + spacing(m);
    @media (min-width: breakpoint(lg)) {
      position: absolute;
      top: 0;
      right: 0;
      min-width: spacing(l);
      max-width: spacing(xl) + spacing(l);
    }
    @media (min-width: breakpoint(lg)) {
      position: relative;
      min-width: spacing(xl);
      max-width: spacing(xl);
    }
    & a {
      display: block;
      & img {
        min-width: spacing(l);
        max-width: spacing(xl) + spacing(l) + spacing(m);
        @media (min-width: breakpoint(sm)) {
          margin-top: spacing(s);
        }
        @media (min-width: breakpoint(lg)) {
          min-width: spacing(xl);
          max-width: spacing(xxl);
          margin-top: 0;
        }
      }
    }
  }
  &.is-stuck {
    background-color: $color-dark-transparent;
    border-bottom: 1px solid $color-main;
  }
}