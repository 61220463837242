@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.co-modal {
  $el: &;
  & .modal-content {
    background-color: $color-body-bg;
  }
  & .modal-header {
    position: relative;
    z-index: 1;
    border: none;
    & .modal-title {
       @include font-38-black();
       color: $color-main;
    }
    & .btn-close {
      background-image: url('closer-d.png');
      background-size: 32px;
      opacity: 1;
      width: spacing(l);
      height: spacing(l);
      cursor: pointer;
    }
  }
  & .modal-body {
    position: relative;
    z-index: 1;
    padding-top: 0;
    & p {
       @include font-18-reg();
      & b {
        @include font-18-black();
      }
    }
  }
  & .modal-footer {
    position: relative;
    z-index: 1;
    border: none;
    justify-content: center;
    & button {
      margin-left: spacing(l);
    }
  }
}