@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.co-cataloghi {
  $el: &;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  @media (min-width: breakpoint(lg)) {
    flex-direction: row;       
    // height: 100vh;
    align-items: flex-start;
    padding: 8.5vw 0;
  }
  &__left {
    @media (min-width: breakpoint(lg)) {
      padding-top: 7.5vw;
    }
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 2;
    margin-top: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;    
      flex-flow: wrap;
    }
    @media (min-width: breakpoint(xl)) {
      width: 55%;
      margin: auto;
    }
    &-elem {
      position: relative;
      width: 100%;
      padding: spacing(s);
      @media (min-width: breakpoint(lg)) {
        padding: spacing(m);
        width: 50%;    
      }
      @media (min-width: breakpoint(xl)) {
        width: calc(100% / 3 - 32px);    
        padding: spacing(l);
      }
      &-img {
        @media (min-width: breakpoint(lg)) {
        }
        @media (min-width: breakpoint(xl)) {
        }
      }
      &-titolo-bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        overflow: hidden;
        display: block;
        background-color: $color-main-transparent;
        text-align: center;
        transition: all 450ms ease-in-out;
        opacity: 0;
        
      }
      &-titolo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: spacing(m);
        display: block;
        @include font-16-black();
        color: $color-white;
        text-transform: uppercase;
        line-height: 1.25;
        transition: all 550ms ease-in-out;
        transition-delay: 300ms;
        opacity: 0;
      }
      &:hover {
        #{$el}__box-elem-titolo {
          opacity: 1;
        }
        #{$el}__box-elem-titolo-bg {
          opacity: 1;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}