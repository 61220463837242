@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.ca-general-plus {
  $el: &;
  display: inline-block;
  width: spacing(l);
  height: spacing(l);
  min-width: spacing(l);
  min-height: spacing(l);
  background: $color-main;
  border: 2px solid $color-main;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
  transition: all 350ms ease-in-out;
  z-index: zindex(xl);
  &::before {
    content: 'i';
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -50%);
    @include font-18-reg();
    font-style: italic;
    color: $color-white;
    transition: all 350ms ease-in-out;
  }
  &:hover {
    background-color: $color-white;
    &::before {
      color: $color-main;
    }
  }
}
