@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cm-servizi {
    $el: &;
    position: relative;
    &__info {
        position: relative;
        text-align: center;
        margin-bottom: spacing(xl);
        padding-top: spacing(xl);
        @media (min-width: breakpoint(lg)) {
            padding-top: 12vw;
        }
        &--ptop {
            @media (min-width: breakpoint(lg)) {
                padding-top: 3vw;
            }
        }
        &-titolo {
            & h2 {
                @include font-48-black();
                color: $color-main;
                margin-bottom: spacing(l);
            }
        }
        &-testo {
            display: block;
            margin-bottom: spacing(l);
            @include font-16-reg();
            line-height: 1.75;
            @media (min-width: breakpoint(lg)) {
                max-width: 25vw;
                margin: 0 auto;
                margin-bottom: spacing(l);
            }
        }
        &-link {
            & > a.ca-general-button {
                display: inline-block !important;
            }
        }
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: wrap;
        padding-top: 5vw;
        @media (min-width: breakpoint(lg)) {
            width: 70%;
            margin: 0 auto;
        }
        &-elem {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            padding: spacing(m);
            margin-bottom: spacing(l);
            @media (min-width: breakpoint(lg)) {
                padding: spacing(m) 5vw;
                width: calc(100% / 3);
            }
            & img {
                width: spacing(xl) + spacing(m);
                margin-bottom: spacing(m);
            }
            &-titolo {
                @include font-16-bold();
                line-height: 1.75;
                margin-bottom: spacing(m);
                text-align: center;
            }
            &-testo {
                @include font-16-reg();
                line-height: 1.75;
                margin-bottom: spacing(m);
                text-align: center;
            }
            &-link {
                width: spacing(l);
                height: spacing(l);
            }
        }
    }
}