@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;

.ca-general-button {
  $el: &;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  display: inline-block;
  z-index: 0;
  background-color: $color-main;
  border: none;
  border: 2px solid $color-main;
  padding: (spacing(s) + spacing(xs)) spacing(m);
  color: $color-white !important;
  border-radius: spacing(xl);
  transition: all 350ms ease-in-out;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  @media (min-width: breakpoint(lg)) {
    padding: (spacing(s) + spacing(xs)) spacing(l);
  }
  &:hover {
    background: $color-white;
    color: $color-main !important;
  }
  & > span {
    position: relative;
    z-index: 1;
    @include font-16-black();
    text-transform: uppercase;
  }
  &--wide {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  &--neg {
    background-color: $color-transparent;
    color: $color-white !important;
    border: 2px solid $color-white;
    &:hover {
      background-color: $color-main;
      border: 2px solid $color-main;
      color: $color-white !important;
    }
  }
  &--small {
    padding: (spacing(s) + spacing(xs)) spacing(s);
    @media (min-width: breakpoint(md)) {
      padding: (spacing(s) + spacing(xs)) spacing(m);
    }
  }
}
