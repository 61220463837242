@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cp-user {
  $el: &;
  position: relative;

  & input[type=text],
  input[type=password],
  input[type=email],
  input[type=tel] {
    @include font-18-reg();
    color: $color-white;
    border: none;
    border-bottom: spacing(xxs) solid $color-white;
    padding: spacing(s);
    width: 100%;
    border-radius: 0;
    margin-bottom: spacing(m);
    margin-top: 0;
    outline: none;
    background-color: $color-transparent;
  }

  & input[type=submit] {
    width: auto;
    display: block;
    background-color: $color-white;
    border: none;
    padding: (spacing(s) + spacing(xs)) spacing(m);
    color: $color-white !important;
    border-radius: spacing(s);
    transition: all 350ms ease-in-out;
    @include font-16-bold();
    padding: spacing(s) spacing(l);
    margin: spacing(m) auto;
    text-transform: none;
    transition: all 300ms ease-in-out;
    &:hover {
      background: $color-main;
      color: $color-white;
    }
  }
}
