@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cp-advanced-page {
  $el: &;
  position: relative;
  z-index: 2;
  padding: spacing(xl) spacing(m);
  @media (min-width: breakpoint(lg)) {
    padding: 0 spacing(m);
  
  }
  &--chi-siamo {
    padding-top: spacing(xxl);
    @media (min-width: breakpoint(lg)) {
      padding: 0 spacing(m);
    }
  }
  &__intro-block {
    position: relative;
    margin-bottom: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      margin-bottom: 0;
    }
    &-inner {
      position: relative;
      width: 100%;
      // height: 80vh;
      overflow: hidden;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: breakpoint(lg)) {
        height: 100vh;
        flex-direction: row;
      }
    }
    &-sx {
        @media (min-width: breakpoint(lg)) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: 5vw;
          width: 40%;
        }
        @media (min-width: breakpoint(xl)) {
          padding-left: 15vw;
          width: 50%;
      }
    }
    &-dx {
      position: relative;
      width: 100%;
      & .owl-carousel {
        width: 100%;
        position: relative;
        @media (min-width: breakpoint(lg)) {
          height: 65vh;
        }
        & .owl-stage-outer {
          @media (min-width: breakpoint(lg)) {
              height: 100% !important;
            }
            & .owl-stage {
              @media (min-width: breakpoint(lg)) {
                  height: 100% !important;
                }
                & .owl-item {
                  @media (min-width: breakpoint(lg)) {
                      height: 100% !important;
                    }
                    & > img {
                      @media (min-width: breakpoint(lg)) {
                          width: auto !important;
                          height: 100% !important;
                        }
                    }
                }
            }
        }
      }
      @media (min-width: breakpoint(lg)) {
        width: 60%;
      }
      @media (min-width: breakpoint(xl)) {
        width: 50%;
      }
      &-chisiamo {
        position: relative;
        display: flex;
        align-items: flex-end;
        &-img {
          position: relative;
          width: 70%;
          @media (min-width: breakpoint(lg)) {
            width: 35%;
          }
          & img {
            position: relative;
            z-index: 2;
            margin-left: spacing(m);
            @media (min-width: breakpoint(lg)) {
              margin-left: 0;
            }
          }
        }
        &-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 16px;
          left: -16px;
          background-color: $color-main;
          z-index: 1;
        }
        &-info {
          padding-left: spacing(l) + spacing(m);
          min-width: 25%;
          @media (min-width: breakpoint(lg)) {
            padding-left: spacing(l);
          }
          &-logo {
            width: spacing(l);
            margin-bottom: spacing(m);
          }
          &-text {
            @include font-16-reg();
            & strong {
              display: inline-block;
              @include font-16-black();
              margin-bottom: spacing(s);
            }
          }
        }
      }
      &-mappa {
        position: relative;
        width: 100%;
        padding-bottom: spacing(l);
        @media (min-width: breakpoint(lg)) {
          padding-bottom: 0;
          width: 40vw;
        }
        & iframe {
          position: relative;
          z-index: 2;
          margin-left: spacing(m);
          width: 100%;
          height: 450px;
          @media (min-width: breakpoint(lg)) {
            height: 650px;
            margin-left: 0;
          }
        }
      }
    }
    &-titolo {
        @include font-48-black();
        color: $color-main;
        margin-bottom: spacing(l);
    }
    &-testo {
        display: block;
        margin-bottom: spacing(l);
        @include font-16-reg();
        line-height: 1.75;
        @media (min-width: breakpoint(lg)) {
          max-width: 22vw;
        }
    }
    &-link {

    }
  }
  &__chisiamo-logo {
    position: relative;
    height: 45vh;
    margin-bottom: spacing(xl);
  }
  &__content-gallery {
    padding: spacing(xl) spacing(m);
    display: none;
    @media (min-width: breakpoint(lg)) {
      display: block;
      width: 85%;
      margin: 0 auto;
    }
  }
  &__content-gallery-mobile {
    display: block;
    @media (min-width: breakpoint(lg)) {
      display: none;
    }
  }
  &__content-bottom-imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-flow: wrap;
    margin-bottom: spacing(xl);
    @media (min-width: breakpoint(lg)) {
      flex-direction: row;
      width: 65%;
      margin: 0 auto;
      margin-bottom: spacing(xl);
    }
    &-elem {
      width: 100%;
      padding: spacing(m);
      @media (min-width: breakpoint(lg)) {
        width: calc(100% / 3);
        padding: spacing(l) spacing(xl);
      }
      & img {
        position: relative;
        margin-bottom: spacing(m);
      }
      & span {
        @include font-16-reg();
      }
    }
  }
}
