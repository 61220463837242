/* GENERALI */
.gen-ov-hidden {
  overflow: hidden;
}
.gen-w-100 {
  width: 100%;
}
.gen-h-100 {
  height: 100%;
}
.gen-img-w-100 {
  width: 100%;
  height: auto;
}
.gen-img-h-100 {
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .gen-img-h-100 {
    width: auto;
    height: 100%;
  }
}
.gen-p-relative {
  position: relative;
}
.gen-p-absolute {
  position: absolute;
}
.gen-d-none {
  display: none;
}
.gen-d-block {
  display: block;
}
.gen-d-inline-block {
  display: inline-block;
}
.gen-d-flex {
  display: flex;
}
.gen-d-flex-col {
  display: flex;
  flex-direction: column;
}

.gen-container-w-50-c {
  width: 50%;
  margin: 0 auto;
}
.gen-container-w-60-c {
  width: 60%;
  margin: 0 auto;
}
.gen-container-w-70-c {
  width: 70%;
  margin: 0 auto;
}
.gen-container-w-80-c {
  width: 80%;
  margin: 0 auto;
}
.gen-container-w-90-c {
  width: 90%;
  margin: 0 auto;
}
.gen-container-w-50 {
  width: 50%;
}
.gen-container-w-60 {
  width: 60%;
}
.gen-container-w-70 {
  width: 70%;
}
.gen-container-w-80 {
  width: 80%;
}
.gen-container-w-90 {
  width: 90%;
}

/* PADDING */
.gen-pb-s {
  padding-bottom: 8px;
}
.gen-pb-m {
  padding-bottom: 16px;
}
.gen-pb-l {
  padding-bottom: 32px;
}
.gen-pb-xl {
  padding-bottom: 64px;
}
.gen-pb-xxl {
  padding-bottom: 128px;
}
.gen-pb-xxxl {
  padding-bottom: 256px;
}

.gen-pt-s {
  padding-top: 8px;
}
.gen-pt-m {
  padding-top: 16px;
}
.gen-pt-l {
  padding-top: 32px;
}
.gen-pt-xl {
  padding-top: 64px;
}
.gen-pt-xxl {
  padding-top: 128px;
}
.gen-pt-xxxl {
  padding-top: 256px;
}

/* MARGIN */
.gen-mb-s {
  margin-bottom: 8px;
}
.gen-mb-m {
  margin-bottom: 16px;
}
.gen-mb-l {
  margin-bottom: 32px;
}
.gen-mb-xl {
  margin-bottom: 64px;
}
.gen-mb-xxl {
  margin-bottom: 128px;
}
.gen-mb-xxxl {
  margin-bottom: 256px;
}

.gen-mt-s {
  margin-top: 8px;
}
.gen-mt-m {
  margin-top: 16px;
}
.gen-mt-l {
  margin-top: 32px;
}
.gen-mt-xl {
  margin-top: 64px;
}
.gen-mt-xxl {
  margin-top: 128px;
}
.gen-mt-xxxl {
  margin-top: 256px;
}

.gen-mt--s {
  margin-top: -8px;
}
.gen-mt--m {
  margin-top: -16px;
}
.gen-mt--l {
  margin-top: -32px;
}
.gen-mt--xl {
  margin-top: -64px;
}
.gen-mt--xxl {
  margin-top: -128px;
}
.gen-mt--xxxl {
  margin-top: -256px;
}

/* SPECIALI */

@media (max-width: 991px) {
  .gen-mb-xxxl {
    margin-bottom: 64px;
  }
  .gen-mt--xxl {
    margin-top: -64px;
  }
  .gen-mt-xxl {
    margin-top: 64px;
  }
  .gen-opacity-zero {
    opacity: 1;
  }
  .gen-mb-xl {
    margin-bottom: 32px;
  }
  .gen-mt-xl {
    margin-top: 32px;
  }
}
@media (min-width: 992px) {
  .gen-opacity-zero {
    opacity: 0;
  }
  .gen-text-right {
    text-align: right;
  }
}

.gen-grid-zero,
.gen-grid-zero .row,
.gen-grid-zero *[class^='col-'] {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.gen-mx-auto {
  margin: 0 auto;
}
.gen-my-auto {
  margin: auto;
}
.gen-ml-auto {
  margin-left: auto;
}
.gen-mr-auto {
  margin-right: auto;
}

/* TEXT ALIGN */
.gen-text-center {
  text-align: center;
}
.gen-text-left {
  text-align: left;
}

.gen-mt-normal {
  margin-top: 1.5rem;
}

/* MARGINI CON MQ */
.gen-mt-5vh {
  margin-top: 5vh;
}
.gen-mb-5vh {
  margin-bottom: 5vh;
}
.gen-mt--5vh {
  margin-top: -5vh;
}
.gen-mb--5vh {
  margin-bottom: -5vh;
}
.gen-mt-10vh {
  margin-top: 10vh;
}
.gen-mb-10vh {
  margin-bottom: 10vh;
}
.gen-mt--10vh {
  margin-top: -10vh;
}
.gen-mb--10vh {
  margin-bottom: -10vh;
}
.gen-mt-15vh {
  margin-top: 15vh;
}
.gen-mb-15vh {
  margin-bottom: 15vh;
}
.gen-mt--15vh {
  margin-top: -15vh;
}
.gen-mb--15vh {
  margin-bottom: -15vh;
}
.gen-mt-20vh {
  margin-top: 5vh;
}
@media (min-width: 992px) {
  .gen-mt-20vh {
    margin-top: 20vh;
  }
}
.gen-mb-20vh {
  margin-bottom: 20vh;
}
.gen-mt--20vh {
  margin-top: -20vh;
}
.gen-mb--20vh {
  margin-bottom: -20vh;
}

@media (min-width: 992px) {
  .gen-mt-lg-0 {
    margin-top: 0;
  }
  .gen-mt-lg-20vh {
    margin-top: 20vh;
  }
}


/* TO SET */
/*** MD ****/
@media only screen and (min-width: 768px) {

  .pl-top-85 {
    padding-top: 85px;
  }
  .pl-left-50 {
    padding-left: 50px;
  }
  .pl-right-50 {
    padding-right: 50px;
  }
  .pl-right-150 {
    padding-right: 150px;
  }
}
/*** LG ****/
@media only screen and (min-width: 992px) {

  .pl-left-50 {
    padding-left: 20px;
  }
  .pl-right-50 {
    padding-right: 20px;
  }
  .pl-right-150 {
    padding-right: 50px;
  }
}
/*** XL ****/
@media only screen and (min-width : 1200px) {

  .pl-left-50 {
    padding-left: 50px;
  }
  .pl-right-50 {
    padding-right: 50px;
  }
  .pl-right-150 {
    padding-right: 100px;
  }
}
/*** XXL ****/
@media only screen and (min-width : 1500px) {

  .pl-left-50 {
    padding-left: 50px;
  }
  .pl-right-50 {
    padding-right: 50px;
  }
  .pl-right-150 {
    padding-right: 150px;
  }
}
