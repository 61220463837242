@use '../../settings/typography' as *;

// font 48
@mixin font-48-black() {
  font-family: $font-family-primary;
  font-weight: $font-weight-black;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: 2.15rem;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-xxl;
  }
}
// font 38
@mixin font-38-black() {
  font-family: $font-family-primary;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: 2.5rem;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-xl;
  }
}
// font 18
@mixin font-18-black() {
  font-family: $font-family-primary;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: $font-size-m;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-l;
  }
}
@mixin font-18-reg() {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: $font-size-m;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-l;
  }
}
// font 16
@mixin font-16-black() {
  font-family: $font-family-primary;
  font-weight: $font-weight-extra-bold;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: $font-size-s;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-m;
  }
}
@mixin font-16-bold() {
  font-family: $font-family-primary;
  font-weight: $font-weight-bold;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: $font-size-s;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-m;
  }
}
@mixin font-16-reg() {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-m;
  font-size: $font-size-s;
  @media (min-width:breakpoint(sm)) {
    font-size: $font-size-m;
  }
}