$font-family-primary: "Sen", serif;
$font-family-secondary: "Futura Light";

$font-size-s: 1rem;
$font-size-m: 1.33rem;
$font-size-l: 1.5rem;
$font-size-xl: 3.15rem;
$font-size-xxl: 4rem;

$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$letter-spacing-m: 0;
// $letter-spacing-l: 3px;

$line-height-s: 0.5;
$line-height-m: 1;
$line-height-l: 1.25;
$line-height-xl: 1.5;
$line-height-xxl: 1.7;
$line-height-xxxl: 2;
