@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cm-menu-mobile {
  $el: &;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: $color-body-bg;
  width: 100%;
  height: 86.5vh;
  z-index: 99;
  text-align: left;
  overflow: hidden;
  border: 4px solid $color-main;
  @media (min-width: map-get($grid-breakpoints, xl)) {
    display: none;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: spacing(xl) spacing(xl);
    padding-top: spacing(xxl) + spacing(xl);
    width: 100%;
    vertical-align: middle;
  }
  &__list-item {
    &--0 {
      position: relative;
      list-style-type: none;
      top: -10px;
      opacity: 0;
      transition: all 350ms ease-in-out;
      transition-delay: 150ms;
      margin-bottom: spacing(l);
      & > a {
        display: block;
        padding-bottom: spacing(s);
        width: 100%;
        @include font-16-bold();
        color: $color-white !important;
        transition: all 350ms;
        text-transform: uppercase;
        &.is-active {
          color: $color-main;
        }
      }
    }
  }
}
