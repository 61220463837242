@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cm-menu-main {
    $el: &;
    position: relative;
    &__inner {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        display: none;
        @media (min-width: breakpoint(lg)) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
    &__list-item {
        position: relative;
        display: block;
        & > a {
            position: relative;
            display: block;
            padding: spacing(l);
            color: $color-white !important;
            @include font-16-bold();
            text-transform: uppercase;
            @media (min-width: breakpoint(lg)) {
                padding: 0 spacing(m);
            }
            @media (min-width: breakpoint(xl)) {
                padding: 0 spacing(m);
            }
            @media (min-width: breakpoint(xxl)) {
                padding: 0 spacing(l);
            }
            &::after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 2px;
                background-color: $color-main;
                opacity: 0;
                transition: all 350ms ease-in-out;
            }
            
            &.is-active {
                &::after {
                    width: spacing(l) + spacing(m);
                    opacity: 1;
                }
            }
        }
        &:hover {
            & a {
                &::after {
                    width: spacing(l) + spacing(m);
                    opacity: 1;
                }
            }
        }
    }
}