@use '../../settings/colors' as *;
@use '../../settings/typography' as *;
@use '../../settings/spaces' as *;
@use '../../settings/breakpoints' as *;
@use '../../settings/z-index' as *;
@use '../../tools/typography/base-font-primary' as *;
.cm-block-ttl {
    $el: &;
    position: relative;
    &__sx {
        margin-bottom: spacing(xl);
        @media (min-width: breakpoint(lg)) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 5vw;
            margin: auto;
        }
        @media (min-width: breakpoint(xl)) {
            padding-left: 15vw;
        }
    }
    &__dx {
       position: relative;
       width: 100%;
       & .owl-carousel {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
         @media (min-width: breakpoint(lg)) {
          height: 65vh;
        }
        & .owl-stage-outer {
            @media (min-width: breakpoint(lg)) {
              height: 100% !important;
            }
            & .owl-stage {
                @media (min-width: breakpoint(lg)) {
                    height: 100% !important;
                }
                & .owl-item {
                    border-radius: spacing(m);
                    overflow: hidden;
                    @media (min-width: breakpoint(lg)) {
                        height: 100% !important;
                    }
                    & > img {
                        width: 100% !important;
                        height: auto !important;
                        @media (min-width: breakpoint(lg)) {
                            width: auto !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
        & > img {
            margin-bottom: spacing(l);
        }
       }
    }
    &__titolo {
        @include font-48-black();
        color: $color-main;
        margin-bottom: spacing(l);
    }
    &__testo {
        display: block;
        margin-bottom: spacing(l);
        @include font-16-reg();
        line-height: 1.75;
        @media (min-width: breakpoint(lg)) {
            max-width: 22vw;
        }
    }
    &__link {

    }
    &__dx-box-idee {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        &-elem {
            position: relative;
            width: calc(100% / 2 - 16px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-main;
            border-radius: spacing(m);
            height: 35vw;
            margin-bottom: spacing(m);
            margin-left: spacing(s);
            margin-right: spacing(s);
            text-align: center;
            transition: all 250ms ease-in-out;
            @media (min-width: breakpoint(lg)) {
                margin-bottom: spacing(l);
                margin-left: spacing(l);
                margin-right: spacing(l);
                height: 8vw;
                width: calc(45% - 45px);
            }
            @media (min-width: breakpoint(xl)) {
                height: 8.5vw;
                width: calc(100% / 3 - 75px);
            }
            @media (min-width: breakpoint(xxl)) {
                margin-left: spacing(s);
                margin-right: spacing(s);
                height: 7vw;
                width: calc(100% / 4 - 25px);
            }
            @media (min-width: breakpoint(xxxl)) {
                margin-left: spacing(m);
                margin-right: spacing(m);
                height: 7vw;
                width: calc(100% / 4 - 55px);
            }
            & span {
                display: block;
                position: relative;
                text-transform: uppercase;
                @include font-16-bold();
                transition: all 250ms ease-in-out;
                color: $color-white !important;
            }
            &:hover {
                background-color: $color-main;
            }
        }
    }
}